import { graphql } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import Fade from "react-reveal/Fade";
import { Heading } from "rebass";
import CallToAction from "../components/CallToAction";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Paragraph from "../components/Paragraph";
import Section from "../components/Section";
import SEO from "../components/Seo";
import BorderRadiusBox from "../components/StyledComponent/BorderRadiusBox";
import Table from "../components/Table";

const MarketTrendPage = ({ data }) => {
  return (
    <Layout transparentHeader>
      <SEO title="樓市走勢" />

      <PageHeader
        backgroundPosition="0 50%"
        backgroundImage={data.bg.childImageSharp.fluid}
      >
        <Fade delay={100}>
          <Heading color="white" fontSize={[5, 7]}>
            樓市走勢
          </Heading>
        </Fade>
      </PageHeader>

      <Section>
        <Paragraph>
          <strong>樓價指數</strong>
          是於 2010 年以整數 100
          作統計基準，比較過去及現在的樓價升跌比率。以希臘為例，2008 年相對 2010
          年之樓價，其比率為 109.5%，是近年最高紀錄，最低紀錄則為 2017 年 9 月之
          63.08%。而 2018 年底比率是
          64.85%，充分反應現在希臘樓市仍處於低水階段。
        </Paragraph>

        <BorderRadiusBox my={6} mx="auto" width={[1, 1, "70%"]}>
          <Img
            fluid={data.markdown.frontmatter.images[0].childImageSharp.fluid}
          />
        </BorderRadiusBox>

        <Paragraph mb={2}>
          以下表格中，比較其他國家樓價指數，希臘的樓價指數於2018年底排行第二，僅次於賽浦路斯。
        </Paragraph>

        <Table
          css={`
            td {
              &:last-child {
                width: 15%;
                text-align: left;
              }
            }
          `}
        >
          <thead>
            <tr>
              {data.markdown.frontmatter.table.head.map((v, i) => (
                <th key={i}>{v}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.markdown.frontmatter.table.rows.map(({ column }, i) => (
              <tr key={`table-row-${i}`}>
                {Object.values(column).map((key: string, i) => (
                  <td
                    key={`table-column-${i}`}
                    dangerouslySetInnerHTML={{ __html: key }}
                  />
                ))}
              </tr>
            ))}
          </tbody>
        </Table>

        <Paragraph>
          <strong>置業率</strong>
          是指希臘人口當中有自置物業的百分比
        </Paragraph>

        <BorderRadiusBox my={6} mx="auto" width={[1, 1, "70%"]}>
          <Img
            fluid={data.markdown.frontmatter.images[1].childImageSharp.fluid}
          />
        </BorderRadiusBox>

        <Paragraph>
          <strong>新樓建成</strong>
          是指每年於希臘新落成的住宅單位數量。由此可見，希臘近年的新樓發展減少，業主一般都以購買二手樓盤為主。
          於下圖中，比較其他國家樓價指數，希臘的樓價指數於 2018
          年底排行第二，僅次於賽浦路斯。
        </Paragraph>

        <BorderRadiusBox my={6} mx="auto" width={[1, 1, "70%"]}>
          <Img
            fluid={data.markdown.frontmatter.images[2].childImageSharp.fluid}
          />
        </BorderRadiusBox>
      </Section>

      <CallToAction />
    </Layout>
  );
};

export default MarketTrendPage;

export const query = graphql`
  {
    bg: file(relativePath: { eq: "bg-4.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    markdown: markdownRemark(fileAbsolutePath: { regex: "/market-trend/" }) {
      frontmatter {
        table {
          head
          rows {
            column
          }
        }
        images {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
